<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col
            :style="
              userRole == 'admin' || userType == 'Account Assistant'
                ? ''
                : 'display: none'
            "
            md="3"
          >
            <b-form-group>
              <label>Sales By</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="
                  userRole == 'admin' || userType == 'Account Assistant' ? 'required' : ''
                "
                name="sales By"
              >
                <v-select
                  placeholder="None"
                  v-model="selecttype"
                  :disabled="ifTreeUser ? true : false"
                  @input="selecttypee($event)"
                  label="name"
                  :options="['Employee', 'ChannelPartner', 'Franchise', 'Direct']"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :style="selecttype == 'Employee' ? '' : 'display: none'" md="3">
            <b-form-group>
              <label>Select Employee</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="
                  userRole == 'employee' && selecttype !== 'Account Assistant'
                    ? 'required'
                    : ''
                "
                name="Employee"
              >
                <v-select
                  placeholder="None"
                  v-model="selectedTeam"
                  label="fullname"
                  :options="teamOption"
                  @input="userInfo($event)"
                >
                  <template #option="{ name, profile_image, username, surname }">
                    <b-avatar :src="getprofileImage(profile_image)" />
                    <span class="font-weight-bolder"> {{ name }} {{ surname }}</span
                    ><span>({{ username }})</span>
                  </template>
                </v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            :style="
              userRole == 'employee' && userType !== 'Account Assistant'
                ? ''
                : 'display: none'
            "
            md="3"
          >
            <b-form-group>
              <label>Employee</label>
              <v-select placeholder="None" v-model="selectedTeam" disabled label="name" />
            </b-form-group>
          </b-col>
          <b-col
            :style="
              selecttype == 'ChannelPartner' || userRole == 'channelpartner'
                ? ''
                : 'display: none'
            "
            md="3"
          >
            <b-form-group>
              <label>Select Channel Partner</label>
              <v-select
                placeholder="None"
                v-model="selectedTeam"
                label="fullname"
                :options="CPOption"
                @input="userInfo($event)"
              >
                <template #option="{ name, profile_image, username, surname }">
                  <b-avatar :src="getprofileImage(profile_image)" />
                  <span class="font-weight-bolder"> {{ name }} {{ surname }}</span
                  ><span>({{ username }})</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            :style="
              selecttype == 'Franchise' || userRole == 'franchise' ? '' : 'display: none'
            "
            md="3"
          >
            <b-form-group>
              <label>Select Franchise</label>
              <v-select
                placeholder="None"
                v-model="selectedTeam"
                :disabled="userRole == 'franchise' || ifTreeUser ? true : false"
                label="fullname"
                :options="FranchiseOption"
                @input="userInfo($event)"
              >
                <template #option="{ name, profile_image, username, surname }">
                  <b-avatar :src="getprofileImage(profile_image)" />
                  <span class="font-weight-bolder"> {{ name }} {{ surname }}</span
                  ><span>({{ username }})</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col :style="userRole == 'franchise' ? '' : 'display: none'" md="3">
            <b-form-group>
              <label>Franchise</label>
              <v-select placeholder="None" v-model="selectedTeam" disabled label="name" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Booking Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Booking Date"
              >
                <flat-pickr
                  v-model="bookingdate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    minDate:
                      userRole == 'admin' || userType == 'Account Assistant'
                        ? ''
                        : 'today',
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" :style="userDetails ? '' : 'display:none'">
            <b-row>
              <b-col md="3">
                <label>Email</label>
                <b-form-input disabled v-model="userDetails.email"
              /></b-col>
              <b-col md="3">
                <label>Phone No</label>
                <b-form-input disabled v-model="userDetails.mobile"
              /></b-col>
              <b-col md="3">
                <label>Username</label>
                <b-form-input disabled v-model="userDetails.username"
              /></b-col>
              <b-col md="3">
                <label>Photo :</label>
                <b-media-aside>
                  <b-link>
                    <b-img
                      rounded
                      :src="
                        userDetails.profile_image
                          ? userDetails.profile_image
                          : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                      "
                      height="80"
                    />
                  </b-link> </b-media-aside
              ></b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-card-code title="Application Form" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Select Project</label>
              <v-select
                placeholder="None"
                v-model="selectedProject"
                label="projectname"
                :options="projectOption"
                @input="getPlot($event.id)"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Suggested Plot No</label>
              <v-select
                placeholder="None"
                v-model="selectedPlot"
                label="plotname"
                :options="plotOption"
                @input="getPlotById($event)"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Booking Type </label>
              <v-select
                placeholder="None"
                @input="handelClickBooking($event)"
                v-model="bookingtype"
                label="name"
                :options="bookingOptions"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            v-if="visible"
            class="d-flex align-items-center"
            style="overflow: auto"
          >
            <ImageMapView
              :value="phaseImage"
              :projectId="projectId"
              :plotOption="plotOption"
              :plots="plotObj"
              @input="(zones) => change({ zones })"
            />
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Plot Rate</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="PlotRate">
                <b-form-input
                  v-model="plotRate"
                  placeholder="Enter Plot Rate"
                  :disabled="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Plot Area(Sq. Yard)</label>

              <b-form-input
                v-model="plotAreaYard"
                placeholder="Enter Plot Area(Sq. Yard)"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Plot Area(Sq.Feet)</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="PlotArea(Sq.Feet)"
              >
                <b-form-input
                  v-model="plotAreaFeet"
                  placeholder="Enter Plot Area(Sq.Feet)"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Offer</label>
              <v-select
                placeholder="None"
                v-model="offer"
                label="name"
                :options="offerOptions"
                @input="handleOffer($event)"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="userRole == 'admin' || userType == 'Account Assistant'" md="3">
            <b-form-group>
              <label>Discount </label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Discount">
                <b-form-input
                  v-model="discount"
                  placeholder="Enter Discount"
                  @input="calcPlotValue()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Remarks</label>
              <b-form-input v-model="disremarks" placeholder="Enter remarks" />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Discount Attachment</label>
              <b-form-file
                v-model="discount_attachment"
                placeholder="Select Document"
                drop-placeholder="Drop file here..."
                @input="handleMultiFileChange($event, 'sales', 'discount_attachment')"
              />
            </b-form-group>
            <attachment :data="discount_attachment" />
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Plot Value</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="PlotValue"
              >
                <b-form-input
                  v-model="plotValue"
                  placeholder="Enter Plot Value"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            :style="
              bookingtype == 'EMI' || bookingtype == 'Part Payment' ? '' : 'display: none'
            "
            md="3"
          >
            <b-form-group>
              <label>Booking Min Value/Amount in %</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="
                  bookingtype == 'EMI' || bookingtype == 'Part Payment' ? 'required' : ''
                "
                name="Booking Min Value %"
              >
                <b-form-input
                  v-model="bookingminvalueper"
                  type="number"
                  placeholder="Enter Booking Min Value in per"
                  @input="clickMinValuePer"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            :style="
              bookingtype == 'EMI' || bookingtype == 'Part Payment' ? '' : 'display: none'
            "
            md="3"
          >
            <b-form-group>
              <label>Booking Min Value/Amount</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="
                  bookingtype == 'EMI' || bookingtype == 'Part Payment' ? 'required' : ''
                "
                name="Booking Min Value"
              >
                <b-form-input
                  v-model="bookingminvalue"
                  type="number"
                  placeholder="Enter Booking Min Value"
                  @input="calcPlotValue()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            :style="
              bookingtype == 'EMI' || bookingtype == 'Part Payment' ? '' : 'display: none'
            "
          >
            <b-form-group>
              <label>Agreement Amount in %</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="
                  bookingtype == 'EMI' || bookingtype == 'Part Payment' ? 'required' : ''
                "
                name="Agreement %"
              >
                <b-form-input
                  v-model="agreementper"
                  placeholder="Enter Agreement Amount in %"
                  @input="clickAgreementPer()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            :style="
              bookingtype == 'EMI' || bookingtype == 'Part Payment' ? '' : 'display: none'
            "
          >
            <b-form-group>
              <label>Agreement Amount</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="
                  bookingtype == 'EMI' || bookingtype == 'Part Payment' ? 'required' : ''
                "
                name="agreement"
              >
                <b-form-input
                  v-model="agreement"
                  placeholder="Enter Agreement Amount"
                  @input="clickAgreement()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Payment Terms Days</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="PaymentTerms
              Days"
              >
                <b-form-input
                  v-model="paymenttermday"
                  placeholder="Enter Payment Terms Days"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Payment Type</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Payment Type"
              >
                <v-select
                  placeholder="None"
                  v-model="selectedPaymentType"
                  label="name"
                  @input="calcFirstpayment"
                  :options="['Down Payment', 'Token']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Payment Through</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Payment Through"
              >
                <v-select
                  placeholder="None"
                  v-model="selectedPaymentThrough"
                  label="name"
                  :options="paymentThroughOption"
                  @input="handleSelectPayment"
                /><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Select Bank</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Bank">
                <v-select
                  v-model="bank"
                  placeholder="None"
                  label="name"
                  :options="assignBank"
                  @input="handleClickBank($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12"></b-col>
          <b-col
            md="4"
            :style="selectedPaymentThrough == 'By UPI' ? 'display:flex' : 'display: none'"
            class="flex-column align-items-center"
          >
            <label>Qr Code</label>
            <div>
              <img :src="qrcode" :width="300" @click="handleOpenImage(qrcode)" />
            </div>
            <!-- <attachment :data="qrcode" /> -->
          </b-col>
          <b-col
            :md="selectedPaymentThrough == 'By UPI' ? 8 : 12"
            class="mb-1"
            :style="bank && bank.id && bank.action ? '' : 'display: none'"
          >
            <b-row class="d-flex justify-content-start bankDetails flex-wrap">
              <b-col
                :md="selectedPaymentThrough == 'By UPI' ? 6 : 3"
                sm="6"
                class="bankDetailsChild"
                :style="bank.beneficiaryname ? '' : 'display: none'"
              >
                <h5><b>Beneficiary Name:</b></h5>
                <span>{{ bank.beneficiaryname }}</span>
              </b-col>
              <b-col
                :md="selectedPaymentThrough == 'By UPI' ? 6 : 3"
                sm="6"
                class="bankDetailsChild"
                :style="bank.accountno ? '' : 'display: none'"
              >
                <h5><b>Account No:</b></h5>
                <span>{{ bank.accountno }}</span>
              </b-col>
              <b-col
                :md="selectedPaymentThrough == 'By UPI' ? 6 : 3"
                sm="6"
                class="bankDetailsChild"
                :style="bank.bankbranch ? '' : 'display: none'"
              >
                <h5><b>Branch Name:</b></h5>
                <span>{{ bank.bankbranch }}</span>
              </b-col>
              <b-col
                :md="selectedPaymentThrough == 'By UPI' ? 6 : 3"
                sm="6"
                class="bankDetailsChild"
                :style="bank.ifsccode ? '' : 'display: none'"
              >
                <h5><b>IFSC Code:</b></h5>
                <span>{{ bank.ifsccode }}</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Transaction Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="noChequeField ? 'required' : ''"
                name="Date"
              >
                <flat-pickr
                  v-model="bankdate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-card-code title="Payment Details" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="3" :style="noChequeField ? '' : 'display: none'">
            <b-form-group>
              <label>Bank Name</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="noChequeField ? 'required' : ''"
                name="Bank Name"
              >
                <b-form-input v-model="bankName" placeholder="Enter Bank Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" :style="noChequeField ? '' : 'display: none'">
            <b-form-group>
              <label>Branch Name</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="noChequeField ? 'required' : ''"
                name="Branch Name"
              >
                <b-form-input v-model="bankbranch" placeholder="Enter Branch Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" :style="noChequeField ? '' : 'display: none'">
            <b-form-group>
              <label>Reference Number</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="noChequeField ? 'required' : ''"
                name="Reference Number"
              >
                <b-form-input v-model="refno" placeholder="Enter Reference Number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3" :style="noChequeField ? '' : 'display: none'">
            <b-form-group>
              <label>Remarks</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="noChequeField ? 'required' : ''"
                name="Remarks"
              >
                <b-form-textarea id="Remarks" v-model="bankremarks" rows="1" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" :style="chequeField ? '' : 'display: none'">
            <b-form-group>
              <label>Cheque No</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="chequeField ? 'required' : ''"
                name="Cheque No"
              >
                <b-form-input v-model="chequeNo" placeholder="Enter Cheque No" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" :style="chequeField ? '' : 'display: none'">
            <b-form-group>
              <label>Bank Name</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="chequeField ? 'required' : ''"
                name="Bank Name"
              >
                <b-form-input v-model="bankName" placeholder="Enter Bank Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" :style="chequeField ? '' : 'display: none'">
            <b-form-group>
              <label>Branch Name</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="chequeField ? 'required' : ''"
                name="Branch Name"
              >
                <b-form-input v-model="bankbranch" placeholder="Enter Branch Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" :style="chequeField ? '' : 'display: none'">
            <b-form-group>
              <label>Cheque Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="chequeField ? 'required' : ''"
                name="Cheque Date"
              >
                <flat-pickr
                  v-model="chequeDate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3" :style="chequeField ? '' : 'display: none'">
            <b-form-group>
              <label>Status</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="chequeField ? 'required' : ''"
                name="Status"
              >
                <v-select
                  placeholder="None"
                  v-model="status"
                  label="name"
                  :options="['Pending', 'Bounce', 'Clear']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-col md="3">
              <b-form-group>
                <label>Attachment</label>
                <label style="color: red !important">*</label>

                <validation-provider
                  #default="{ errors }"
                  :rules="chequeField ? 'required' : ''"
                  name="Attachment"
                >
                  <b-form-file
                    v-model="bankreceipt"
                    placeholder="Select Document"
                    drop-placeholder="Drop file here..."
                    @input="handleMultiFileChange($event, 'sales', 'bankreceipt')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <attachment :data="bankreceipt" />
            </b-col>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Total Payment</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Total Payment"
              >
                <b-form-input
                  v-model="totalPayment"
                  placeholder="Enter Total Payment"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>First Payment</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="firstPaymentRules"
                name="First Payment"
              >
                <b-form-input
                  v-model="firstpayment"
                  type="number"
                  placeholder="Enter First Payment"
                  @input="calcFirstpayment"
                />
                <small class="text-danger">{{
                  errors[0] == "The First Payment field must be 10000 or more"
                    ? "The First Payment field must be accept more than 5 figure Amount"
                    : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Remaining Payment</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required|min_value:0"
                name="Remaining Payment"
              >
                <b-form-input
                  v-model="remainingPayment"
                  placeholder="Enter Remaining Payment"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            :style="
              bookingtype == 'EMI' || bookingtype == 'Part Payment' ? '' : 'display: none'
            "
            md="12"
          >
            <!-- <b-col md="12"> -->
            <b-card-code title="INSTALLMENTS" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col :style="bookingtype == 'EMI' ? '' : 'display: none'" md="3">
            <!-- <b-col md="3"> -->
            <b-form-group>
              <label>Installment start date</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="bookingtype == 'EMI' ? 'required' : ''"
                name="Installment start date"
              >
                <flat-pickr
                  v-model="installmentStartDate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                  }"
                  style="background-color: transparent"
                  @input="handleBetweenMonth(betweenMonth)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :style="bookingtype == 'EMI' ? '' : 'display: none'" md="3">
            <!-- <b-col md="3"> -->
            <b-form-group>
              <label>No. of Installment</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="bookingtype == 'EMI' ? 'required' : ''"
                name="No. of Installment"
              >
                <v-select
                  label="month"
                  placeholder="None"
                  v-model="noofemi"
                  :options="emiOptions"
                  @input="handleInstallment($event, 'noInstallment')"
                />
                <!-- <b-form-input v-model="noInstallment"
                  placeholder="Enter No. of Installment" @input="handleInstallment($event, 'noInstallment')" /> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            :style="
              bookingtype == 'EMI' && noofemi && noofemi.rate ? '' : 'display: none'
            "
            md="3"
          >
            <!-- <b-col md="3"> -->
            <b-form-group>
              <label>Interest Rate (%)</label>
              <b-form-input
                v-model="noofemi.rate"
                placeholder="Enter No. of Installment"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col :style="bookingtype == 'EMI' ? '' : 'display: none'" md="3">
            <!-- <b-col md="3"> -->
            <b-form-group>
              <label>Gap between months</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="bookingtype == 'EMI' ? 'required' : ''"
                name="Gap between months"
              >
                <b-form-input
                  v-model="betweenMonth"
                  placeholder="Enter Gap between months"
                  @input="handleBetweenMonth($event, 'betweenMonth')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col :style="bookingtype == 'EMI' ? '' : 'display: none'" md="3">
            <!-- <b-col md="3"> -->
            <b-form-group>
              <label>Installment Amount</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="bookingtype == 'EMI' ? 'required' : ''"
                name="Installment Amount"
              >
                <b-form-input
                  v-model="installment_amount"
                  placeholder="Enter Installment Amount"
                  @input="handleInstallment_amount($event, 'installment_amount')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            :style="
              bookingtype == 'EMI' || bookingtype == 'Part Payment' ? '' : 'display: none'
            "
            md="12"
          >
            <!-- <b-col md="12"> -->
            <table class="table table-bordered">
              <thead class="text">
                <tr>
                  <th></th>
                  <th>Installment date</th>
                  <th>Installment Amount</th>
                  <th>Remarks</th>
                  <th :style="bookingtype == 'Part Payment' ? '' : 'display:none'">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(installment, index) in installments" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <flat-pickr
                      v-model="installment.installment_date"
                      class="form-control"
                      :config="{
                        dateFormat: 'd/m/Y',
                        defaultDate: new Date(),
                      }"
                      style="background-color: transparent"
                    />
                  </td>
                  <td>
                    <b-form-input
                      v-model="installment.installment_amount"
                      placeholder="Enter Installment Amount"
                    />
                  </td>
                  <td>
                    <b-form-input
                      v-model="installment.remarks"
                      placeholder="Enter Installment Remarks"
                    />
                  </td>
                  <td :style="bookingtype == 'Part Payment' ? '' : 'display:none'">
                    <b-button
                      variant="outline-danger"
                      class="mt-0"
                      @click="deleteRow(index)"
                    >
                      <feather-icon icon="TrashIcon" class="" />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-button
              :style="bookingtype == 'Part Payment' ? '' : 'display:none'"
              variant="primary"
              class="mt-1"
              @click="addRow"
            >
              Add more
            </b-button>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group>
              <label>Ledger</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Number"
              >
                <v-select placeholder="None"
                  v-model="selectedLedger"

                  label="name"
                  :options="ledgerOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="12">
            <b-card-code title="Application Name" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Title</label>
              <v-select
                placeholder="None"
                v-model="selectedtitle"
                label="name"
                :options="['Shri.', 'Smt.', 'Ms.', 'Mrs.', 'Mr.']"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-row>
              <b-col>
                <b-form-group>
                  <label>Customer Name</label>
                  <label style="color: red !important">*</label>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Customer Name"
                  >
                    <v-select
                      v-model="cfirstname"
                      :options="customerOption"
                      label="fullname"
                      @input="getCustomerDetails($event)"
                      placeholder="None"
                    >
                      <template #option="{ fullname, profile_image, approved1 }">
                        <b-avatar :src="getprofileImage(profile_image)" />
                        <span class="font-weight-bolder text-danger"> {{ fullname }}</span
                        ><span v-if="approved1 != 'Approved'" class="text-danger">
                          ( Not approved )
                        </span>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <label></label>
              <label></label>
              <div class="d-flex align-items-center" style="height: 82px">
                <b-button variant="primary" size="sm" @click="handlePopUp"> + </b-button>
              </div>
            </b-row>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label>Address</label>
              <b-form-textarea id="Address" v-model="address" rows="1" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Pin Code</label>
              <b-form-input
                disabled
                v-model="pincode"
                maxlength="6"
                @input="clickPincode($event)"
                type="number"
                placeholder="Enter Pin Code"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>

              <b-form-input disabled v-model="city" placeholder="Enter City" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Area</label>

              <b-form-input disabled v-model="area" placeholder="Enter Area" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>

              <b-form-input disabled v-model="state" placeholder="Enter State" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Country</label>
              <b-form-input v-model="country" placeholder="Enter Country" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>DOB</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="DOB">
                <flat-pickr
                  v-model="dateofbirth"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Mobile No.</label>
              <b-form-input disabled v-model="mobileNo" placeholder="Enter Mobile No." />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Email ID</label>
              <b-form-input disabled v-model="email" placeholder="Enter Email ID" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar No</label>
              <b-form-input
                v-model="adharno"
                maxlength="14"
                @input="validateAadharClick($event)"
                placeholder="Enter Aadhar No"
              />
              <small v-if="adharnoValidate == false" class="text-danger"
                >Enter Valid Aadhar No</small
              >
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pan No</label>
              <b-form-input
                v-model="panno"
                maxlength="10"
                @input="validatePanClick($event)"
                placeholder="Enter PAN No"
              />
              <small v-if="pannoValidate == false" class="text-danger"
                >Enter Valid PAN No</small
              >
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Nominee</label>
              <b-form-input v-model="nominee" placeholder="Enter Nominee" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee Relationship</label>
              <b-form-input
                v-model="nomineerelationship"
                placeholder="Enter Nominee Relationship"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee Phone</label>
              <b-form-input
                v-model="nomineephone"
                maxlength="10"
                placeholder="Enter Nominee Phone"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Nominee DOB</label>
              <flat-pickr
                v-model="nomineedob"
                class="form-control"
                :config="{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                  maxDate: new Date(),
                }"
                style="background-color: transparent"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" :style="ifEdit ? '' : 'display: none'">
            <b-form-group>
              <label>Cancel Sales</label>
              <v-select
                placeholder="None"
                v-model="cancelStatus"
                :disabled="editValue.action ? true : false"
                label="name"
                :options="['Yes', 'No']"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" :style="cancelStatus == 'Yes' ? '' : 'display: none'">
            <b-form-group>
              <label>Remarks</label>
              <b-form-textarea
                id="remarks"
                placeholder="Enter remarks"
                v-model="remarks"
                rows="1"
              />
            </b-form-group>
          </b-col>
          <b-col md="3" :style="cancelStatus == 'Yes' ? '' : 'display: none'">
            <b-form-group>
              <label>Cancellation Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="cancelStatus == 'Yes' ? 'required' : ''"
                name="Cancellation Date"
              >
                <flat-pickr
                  v-model="cancellation_date"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    maxDate: 'today',
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label style="font-size: 16px">Send:&nbsp;</label>
              <b-form-checkbox v-model="sendemail" inline> Email </b-form-checkbox>
              <b-form-checkbox v-model="sendsms" inline> SMS </b-form-checkbox>
              <b-form-checkbox v-model="sendwhatsapp" inline> Whatsapp </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col
            md="12"
            class="mt-2"
            :style="cfirstname && cfirstname.id ? '' : 'display:none'"
          >
            <h3>Customer Information</h3>
          </b-col>

          <b-col md="12" :style="cfirstname && cfirstname.id ? '' : 'display:none'">
            <b-row v-for="(item, id) in extra_customer" :key="id">
              <div
                class="d-flex justify-content-end w-100"
                style="
                  border-bottom: 1px solid rgb(128, 128, 128, 0.2);
                  margin-bottom: 20px;
                "
              >
                <b-button
                  variant="outline-danger"
                  class="mt-0 p-0"
                  @click="removeRow(id)"
                  style="width: 30px; height: 30px"
                >
                  <feather-icon icon="TrashIcon" class="" />
                </b-button>
              </div>
              <b-col md="3">
                <b-form-group>
                  <label>Customer Name</label>
                  <v-select
                    v-model="item.customer"
                    :options="customerOption"
                    label="fullname"
                    placeholder="None"
                    @input="handleExtraCustomer($event, item)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Birth Date</label>
                  <p>{{ item.birthdate }}</p>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Mobile</label>
                  <p>{{ item.mobile }}</p>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Email</label>
                  <p>{{ item.email }}</p>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Aadhar</label>
                  <p>{{ item.aadharcardno }}</p>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group>
                  <label>Pan No</label>
                  <p>{{ item.panno }}</p>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Nominee Name</label>
                  <p>{{ item.nomineename }}</p>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Nomine Relation</label>
                  <p>{{ item.nomineerelation }}</p>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Nominee Birth Date</label>
                  <p>{{ item.nomineedob }}</p>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Nominee Mobile</label>
                  <p>{{ item.nomineephone }}</p>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-button variant="primary" @click="addcsRow()" class="mb-1 mt-1">
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>Add More</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <!-- submit button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              :disabled="flag || submitDisable"
              @click.prevent="submitForm"
              class="mr-1"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
            <b-button
              v-if="
                ifEdit &&
                !editValue.action &&
                (userRole == 'admin' || userType == 'Account Assistant') &&
                cancelStatus == 'Yes'
              "
              class="ml-1"
              variant="danger"
              @click="handleCancel"
              >Cancel
            </b-button>
          </b-col>
        </b-row>
        <b-modal
          id="modal-form"
          v-model="popUpVisible"
          size="lg"
          cancel-variant="outline-secondary"
          ok-title="Submit"
          cancel-title="Close"
          title="Add Customer"
          hide-footer
        >
          <add-customer
            :fromSales="true"
            :selectedUser="selectedTeam"
            :closePopUp="closePopUp"
            :getCustomer="getCustomer"
          />
        </b-modal>
        <b-modal
          id="modal-otp"
          v-model="otpModal"
          size="md"
          cancel-variant="outline-secondary"
          ok-title="Submit"
          cancel-title="Close"
          title="OTP Verification"
          hide-footer
        >
          <b-row>
            <b-col md="12">
              <b-form-group>
                <label>OTP Code</label>
                <label style="color: red !important">*</label>

                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="OTP Code"
                >
                  <b-form-input v-model="otp" type="number" placeholder="Enter OTP" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
                :disabled="flag || submitDisable"
                @click.prevent="submitForm"
                class="mr-1"
              >
                Submit
              </b-button>
              <b-button
                v-if="
                  $route.params.id &&
                  (userType == 'admin' || userType == 'Account Assistant') &&
                  cancelStatus == 'Yes'
                "
                :disabled="this.active == 0"
                class="ml-1"
                variant="danger"
                @click="handleCancel"
                >Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import AddCustomer from "../customer/AddCustomer.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BImg,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormFile,
  BFormCheckbox,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import Attachment from "../../../components/Attechment.vue";
import image from "../../../assets/images/pages/no-image.png";
import ImageMapView from "../../../components/ImageMapView.vue";

export default {
  components: {
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    AddCustomer,
    flatPickr,
    BFormCheckbox,
    PinchScrollZoom,
    Datepicker,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    ImageMapView,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormFile,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BAvatar,
  },
  data() {
    return {
      plotObj: {},
      payment: "",
      remarks: "",
      status: "",
      cancelStatus: "",
      active: 1,
      visible: false,
      phaseImage: {
        uuid: 3721,
        source: image,
      },
      otpModal: false,
      bank: "",
      bankbranch: "",
      bankdate: null,
      bankreceipt: "",
      bankremarks: "",
      bookingtype: "",
      bookingminvalue: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      selectedTeam: "",
      projectId: "",
      teamOption: [],
      CPOption: [],
      FranchiseOption: [],
      selectedProject: "",
      projectOption: [],
      selectedPhase: "",
      phaseOption: [],
      selectedPlot: "",
      plotOption: [],
      refno: "",
      selectedPaymentType: "",
      selectedPaymentThrough: "",
      paymentThroughOption: [
        "By Cheque",
        "By RTGS",
        "By NEFT",
        "By ECS",
        "Debit/Credit Card",
        "Net Banking",
        "By UPI",
        "Other",
      ],
      bookingdate: moment(new Date()).format("DD/MM/yyyy"),
      plotRate: "",
      plotAreaYard: "",
      plotAreaFeet: "",
      discount: "",
      plotValue: "",
      totalPayment: "",
      firstpayment: "",
      chequeField: false,
      chequeNo: "",
      bankName: "",
      chequeDate: moment(new Date()).format("DD/MM/yyyy"),
      status: "",
      firstPaymentRules: "required|min_value:0",
      remainingPayment: "",
      selectedtitle: "",
      nomineerelationship: "",
      qrcode: "",
      ctitleOption: [
        { name: "Shri." },
        { name: "Smt." },
        { name: "Ms." },
        { name: "Mrs." },
        { name: "Mr." },
      ],
      installmentStartDate: null,
      noInstallment: 1,
      betweenMonth: "",
      installment_amount: "",
      selectedLedger: "",
      ledgerOption: [],
      cfirstname: "",
      cmiddlename: "",
      clastname: "",
      address: "",
      country: "India",
      state: "",
      city: "",
      area: "",
      areaOption: [],
      cityOption: [],
      pincode: "",
      dateofbirth: moment(new Date()).format("DD/MM/yyyy"),
      mobileNo: "",
      email: "",
      adharno: "",
      adharnoValidate: true,
      panno: "",
      pannoValidate: true,
      emailsend: "",
      nominee: "",
      nomineeRelationship: "",
      nomineephone: "",
      nomineePhone: "",
      installments: [],
      ifEdit: false,
      bookingtype: "",
      noChequeField: false,
      selecttype: "",
      paymenttermday: "",
      userRole: "",
      userName: "",
      flag: false,
      submitDisable: false,
      dashboard: false,
      fromState: "",
      stateOptions: [],
      StateData: [],
      customerOption: [],
      nomineedob: moment(new Date()).format("DD/MM/yyyy"),
      cancellation_date: moment(new Date()).format("DD/MM/yyyy"),
      sendemail: "",
      sendwhatsapp: "",
      sendsms: "",
      popUpVisible: false,
      userDetails: "",
      agreementper: "",
      agreement: "",
      parent: "",
      bookingminvalueper: "",
      disremarks: "",
      bankLedgerOption: [],
      ifTreeUser: false,
      testPlotValue: "",
      isMobile: false,
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      bank: "",
      discount_attachment: "",
      otp: "",
      userType: "",
      bookingOptions: [],
      emiOptions: [],
      noofemi: {},
      loginData: {},
      extra_customer: [
        {
          customer: "",
          email: "",
          mobile: "",
          birthdate: "",
          panno: "",
          aadharcardno: "",
          nomineename: "",
          nomineerelation: "",
          nomineephone: "",
          nomineedob: "",
        },
      ],
      assignBank: [],
      offerOptions: [],
      allOffer: [],
      offer: "",
      editValue: {},
    };
  },
  destroyed() {
    localStorage.removeItem("plotData");
  },
  async mounted() {
    const rights = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      rights.map((item) => {
        if (item.modulename == "Sales") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/sales");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/sales");
          }
        }
      });
    }
    await this.getBankLedger();
    this.getState();
    if (this.$route.query.userId) {
      this.asPerTreeData();
    }
    const data = localStorage.getItem("userData");
    this.loginData = JSON.parse(localStorage.getItem("userData"));
    this.userRole = this.loginData.role;
    this.userType = this.loginData.type;

    this.userName = this.loginData.name;
    this.userId = this.loginData.id;
    this.init();
    if (localStorage.getItem("plotData")) {
      this.getFollowupData();
    }
    this.getOffer();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
    this.isMobile = window.innerWidth > 500;
  },
  methods: {
    handleOffer(e) {
      if (e && this.plotValue && e.type == "Offer") {
        this.discount = (this.plotValue * 100) / e.value;
        this.calcPlotValue();
      } else {
        this.discount = 0;
        this.calcPlotValue();
      }
    },
    async getBankLedger() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/bankledger`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.bankLedgerOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    handleOpenImage(qrcode) {
      window.open(qrcode, "_blank");
    },
    async asPerTreeData() {
      const requestoption = {
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${this.$route.query.userId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      };
      await axios(requestoption).then((response) => {
        const details = response.data.data[0].role;
        this.treeUserRole =
          details == "channelpartner"
            ? "ChannelPartner"
            : details == "franchise"
            ? "Franchise"
            : "Employee";
        this.ifTreeUser = true;
        this.selecttypee(this.treeUserRole);
        this.userInfo(this.$route.query.userId);
        this.selectedTeam = {
          name: response.data.data[0].name,
          id: this.$route.query.userId,
        };
      });
    },
    clickMinValuePer() {
      this.bookingminvalue = (this.bookingminvalueper * this.totalPayment) / 100;
      this.firstpayment = parseFloat(this.bookingminvalue.toFixed(2));
      this.bookingminvalue = parseFloat(this.bookingminvalue.toFixed(2));
      this.remainingPayment = parseFloat(
        (this.totalPayment - this.firstpayment).toFixed(2)
      );
    },
    clickMinValue() {
      this.bookingminvalueper = (this.bookingminvalue * 100) / this.totalPayment;
    },
    clickAgreementPer() {
      this.agreement = (this.agreementper * this.totalPayment) / 100;
      this.installment_amount = this.totalPayment - this.agreement;
    },
    clickAgreement() {
      this.agreementper = (this.agreement * 100) / this.totalPayment;
      this.installment_amount = this.totalPayment - this.agreement;
    },
    async userInfo(e) {
      let id = null;
      this.cfirstname = "";
      if (e.id) {
        id = e.id;
      } else {
        id = e;
      }
      const requestoption = {
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      };
      await axios(requestoption).then((response) => {
        this.userDetails = response.data.data[0];
        if (this.userDetails.role !== "admin" || this.userType == "Account Assistant") {
          this.getParent(this.userDetails.parent.id);
        }
        this.getCustomer(this.userDetails.id);
      });
    },
    async getParent(id) {
      const requestoption = {
        method: "GET",
        url: `${this.baseApi}/getusersbyid/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      };
      await axios(requestoption).then((response) => {
        this.parent = response.data.data[0].name;
      });
    },
    closePopUp() {
      this.popUpVisible = false;
    },
    handlePopUp() {
      if (this.selectedTeam || this.selecttype == "Direct") {
        this.popUpVisible = true;
      } else {
        this.$swal({
          title: "Warning",
          timer: 5000,
          text: "Plese Select User !",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    handleCancel() {
      this.active = 0;
      this.$swal({
        title: `Are you sure to cancel plot`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Cancel`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.submitForm();
        }
      });
    },
    async getCustomer(id) {
      const data = {
        assignto: id,
      };
      const requestoption = {
        method: "POST",
        url: `${this.baseApi}/getcustomerassignby`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
      };
      await axios(requestoption).then((response) => {
        this.customerOption = response.data.data;
      });
    },
    async getCustomerDetails(e) {
      if (e) {
        const requestoption = {
          method: "GET",
          url: `${this.baseApi}/getusersbyid/${e.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
        };
        await axios(requestoption).then((response) => {
          const details = response.data.data[0];
          this.handleCustomer(details);
        });
      }
    },
    handleCustomer(details) {
      this.email = details.email;
      this.mobileNo = details.mobile;
      this.dateofbirth =
        details.birthdate == "00/00/0000" || details.birthdate == null
          ? null
          : moment(details.birthdate).format("DD/MM/YYYY");
      this.pincode = details.curpincode;
      this.area = details.curarea;
      this.city = details.curcity;
      this.state = details.curstate;
      this.adharno = details.aadharcardno;
      this.panno = details.panno;
      this.nominee = details.nomineename;
      this.nomineerelationship = details.nomineerelation;
      this.nomineephone = details.nomineephone;
      this.nomineedob = details.nomineedob;
    },

    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.city,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.areaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getUserById(id) {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getusersbyid/${id}`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.selectedTeam = response.data.data[0];
          if (this.selectedTeam.role == "channelpartner") {
            this.selecttype = "ChannelPartner";
          } else if (this.selectedTeam.role == "franchise") {
            this.selecttype = "Franchise";
          } else if (this.selectedTeam.role == "employee") {
            this.selecttype = "Employee";
          } else if (
            this.selectedTeam.role == "admin" ||
            this.userType == "Account Assistant"
          ) {
            this.selecttype = "Direct";
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    selecttypee(e) {
      this.selecttype = e;
      this.selectedTeam = null;
      this.cfirstname = "";

      if (this.selectedPlot && this.selectedPlot.id) {
        this.getPlotById(this.selectedPlot);
      } else {
        this.selectedPlot = "";
        this.plotRate = "";
        this.plotAreaFeet = "";
        this.selectedPlot = "";
        this.plotValue = "";
        this.totalPayment = "";
        this.payment = "";
        this.testPlotValue = "";
      }
      if (this.selecttype == "Direct") {
        this.selectedTeam = {
          id: this.userId,
        };
        this.userInfo(this.userId);
        this.getCustomer(this.userId);
      }
    },
    handleClickBank(event) {
      this.qrcode = event.qrcode;
    },
    async handleMultiFileChange(e, type, name) {
      this.flag = true;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this[name] = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.flag = false;
        })
        .catch((error) => console.log(error, "error"));
    },
    handelClickBooking(e) {
      this.agreementper = "";
      this.agreement = "";
      this.bookingtype = e;
      this.handlePartPayment();
      this.$forceUpdate();
    },
    handlePartPayment() {
      this.installments = [
        {
          installment_date: moment(new Date()).format("DD/MM/YYYY"),
          installment_amount: "",
          remarks: "",
          type: "Part Payment",
        },
      ];
    },
    getFollowupData() {
      const plotData = JSON.parse(localStorage.getItem("plotData"));
      this.dashboard = plotData.dashboard;
      this.selectedProject = plotData.project;
      this.selectedPlot = plotData.plot;
      this.cfirstname = plotData.client ? plotData.client : {};
      this.email = this.cfirstname.email;
      this.mobileNo = this.cfirstname.mobile;
      this.birthdate = this.cfirstname.birthdate;
      this.pincode = this.cfirstname.pincode;
      this.area = this.cfirstname.area;
      this.city = this.cfirstname.city;
      this.state = this.cfirstname.state;
      this.customer = plotData.client && this.getUserById(this.cfirstname.assignto);
      this.getPlot(plotData.projectid);
      this.getPlotById(plotData.plot);
      this.$forceUpdate();
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/getsalesbyid/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.editValue = json.data.data.length > 0 ? json.data.data[0] : {};
          this.getEditItems(this.editValue);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      // this.installmentStartDate = item.installmentsdate
      //   ? new Date(item.installmentsdate.split("/").reverse().join("-"))
      //   : "";
      this.installmentStartDate =
        item.installmentsdate == "00/00/0000"
          ? null
          : moment(item.installmentsdate).format("DD/MM/yyyy");
      this.selecttype = item.branch;
      this.selectedTeam = item.team ? item.team : {};
      this.bookingdate =
        item.bookingdate == "00/00/0000"
          ? null
          : moment(item.bookingdate).format("DD/MM/yyyy");
      this.bookingtype = item.booktype;
      this.selectedProject = item.project ? item.project : {};
      // this.phase = item.phase;
      this.selectedPlot = item.plotno ? item.plotno : {};
      this.getPlot(this.selectedProject.id);
      this.chequeDate = moment(item.chequedate).format("DD/MM/YYYY");
      this.discount = item.discount;
      this.disremarks = item.disremarks;
      this.plotValue = item.plotvalue;
      this.paymenttermday = item.paymenttermday;
      this.selectedPaymentType = item.paymenttype;
      this.selectedPaymentThrough = item.paymentthrough;
      this.totalPayment = item.totalpayment;
      this.firstpayment = item.firstpayment;
      this.remainingPayment = parseFloat(item.remainingpayment);
      this.betweenMonth = item.monthgap;
      this.installment_amount = item.installmentamt;
      this.noInstallment = item.noofinstallment;
      this.ledger = item.ledger;
      this.title = item.title;
      this.cfirstname = { fullname: item.cfirstname, id: item.cfirstid };
      this.refno = item.refno;
      this.bankreceipt = item.bankreceipt;
      this.bankdate =
        item.bankdate == "00/00/0000"
          ? null
          : moment(item.bankdate, "yyyy-MM-DD").format("DD/MM/yyyy");
      this.bankremarks = item.bankremarks;
      // this.cmiddlename = item.cmiddlename
      // this.clastname = item.clastname
      this.address = item.address;
      this.country = item.country;
      this.state = item.state;
      this.city = item.city;
      this.area = item.area;
      this.pincode = item.pincode;
      this.dateofbirth =
        item.dateofbirth == "00/00/0000"
          ? null
          : moment(item.dateofbirth).format("DD/MM/yyyy");
      this.mobileNo = item.mobileno;
      this.email = item.email;
      this.adharno = item.adharno;
      this.panno = item.panno;
      this.emailsend = item.emailsend;
      this.nominee = item.nominee;
      this.nomineerelationship = item.nomineerelationship;
      this.nomineephone = item.nomineephone;
      this.nomineedob = item.nomineedob;
      this.selectedtitle = item.title;
      this.bankName = item.bankname;
      this.bankbranch = item.bankbranch;
      this.bookingminvalue = item.bookingminvalue;
      this.chequeNo = item.chequeno;
      this.installments = item.saleitems;
      this.cancellation_date =
        item.cancellation_date == "00/00/0000"
          ? null
          : moment(item.cancellation_date).format("DD/MM/yyyy");
      this.status = item.status;
      this.active = item.active;
      this.remarks = item.remarks;
      this.agreementper = item.agreementper;
      this.agreement = item.agreement;
      this.bookingminvalueper = item.bookingminvalueper;
      this.sendemail = item.sendemail == 1;
      this.sendsms = item.sendsms == 1;
      this.sendwhatsapp = item.sendwhatsapp == 1;
      this.cfirstid = item.cfirstid;
      this.bank = item.bank ? item.bank : {};
      // this.clickPincode()
      this.handleCity();
      this.handleArea();
      this.userDetails = this.selectedTeam;
      this.offer = item.offer;
      this.parent =
        this.selectedTeam.parent && this.selectedTeam
          ? this.selectedTeam.parent.name
          : "";
      this.handleSelectPayment(item.paymentthrough);
      this.payment = parseFloat(item.plotno.plotamount);
      this.noofemi = { month: this.noInstallment };
      this.extra_customer = item.extra_customer_data;
      this.discount_attachment = item.discount_attachment;
      this.getCustomer(this.userDetails.id);
      this.cancelStatus = item.action ? "Yes" : "";
      this.getPlotById(this.selectedPlot);

      let emi = JSON.parse(this.selectedProject.emi);
      if (emi) {
        emi.map((item) => {
          if (
            item.fromMonth <= this.noInstallment &&
            item.toMonth >= this.noInstallment
          ) {
            this.noofemi.rate = item.rate;
          }
        });
      }
      this.extra_customer.map((item2) => {
        item2.customer = item2;
      });
      // console.log(this.installments)
      // this.$forceUpdate()
    },
    scalingHandler(e) {},
    reset() {
      //   (this.$refs.zoomer as PinchScrollZoom).setData({
      //   scale: 1,
      //   originX: 0,
      //   originY: 0,
      //   translateX: 0,
      //   translateY: 0
      // });
    },
    handleExtraCustomer(e, item) {
      if (e) {
        let array = this.extra_customer.filter(
          (item) => item.customer && item.customer.id == e.id
        );
        if (this.cfirstname && this.cfirstname.id !== e.id && array.length == 1) {
          item.email = e.email;
          item.mobile = e.mobile;
          item.birthdate = e.birthdate;
          item.panno = e.panno;
          item.aadharcardno = e.aadharcardno;
          item.nomineename = e.nomineename;
          item.nomineerelation = e.nomineerelation;
          item.nomineephone = e.nomineephone;
          item.nomineedob = e.nomineedob;
        } else {
          item.customer = null;
          item.email = "";
          item.mobile = "";
          item.birthdate = "";
          item.panno = "";
          item.aadharcardno = "";
          item.nomineename = "";
          item.nomineerelation = "";
          item.nomineephone = "";
          item.nomineedob = "";
        }
      } else {
        item.customer = null;
        item.email = "";
        item.mobile = "";
        item.birthdate = "";
        item.panno = "";
        item.aadharcardno = "";
        item.nomineename = "";
        item.nomineerelation = "";
        item.nomineephone = "";
        item.nomineedob = "";
      }
    },
    handleSelectPayment(e) {
      const name = e;
      if (name == "By Cheque") {
        this.chequeField = true;
      } else {
        this.chequeField = false;
      }
      if (name !== "Other" && name !== "By Cheque") {
        this.noChequeField = true;
      } else {
        this.noChequeField = false;
      }
      if (name == "By UPI") {
        this.handleClickBank(this.bank);
      }
    },
    handleImage() {
      this.visible = true;
    },
    handlePincode() {
      if (this.fromState == true) {
        this.pincode = this.area.Pincode;
      }
    },
    async handleCity() {
      this.areaOption = [];
      this.cityOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.state,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.cityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.city,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.areaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }
    },
    async handleOtp() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (
          success == false ||
          this.pannoValidate == false ||
          this.adharnoValidate == false
        ) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.submitDisable = false;
          return;
        }
        if (this.bookingtype == "Part Payment") {
          let total = parseFloat(this.firstpayment);
          this.installments.map((item) => {
            total += parseFloat(item.installment_amount);
          });
          if (parseFloat(total.toFixed(2)) !== parseFloat(this.totalPayment)) {
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: "Installmant amount should be equal to total payment",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.submitDisable = false;
            return;
          }
        }
        if (success && this.pannoValidate == true && this.adharnoValidate == true) {
          this.otpModal = true;
          const reportauthority = {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            url: `${this.baseApi}/sales-otp`,
            data: {
              mobile: `${this.cfirstname.mobile}`,
              email: `${this.cfirstname.email}`,
              id: `${this.cfirstname.id}`,
            },
          };
          await axios(reportauthority)
            .then((res) => {})
            .catch((res) => {
              this.$swal({
                title: "Error",
                timer: 5000,
                text: "Some Error Occured",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.submitDisable = false;
            });
        }
      });
    },
    async clickPincode() {
      this.area = "";
      this.city = "";
      this.state = "";
      this.cityOption = [];
      this.areaOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.pincode)) {
        this.fromState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.pincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.cityOption = [];
            this.StateData = response.data.data;
            this.areaOption = response.data.data;
            this.state = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.cityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    validatePanClick(e) {
      const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      this.panno = this.panno.toUpperCase();
      if (regex.test(this.panno) == true) {
        this.pannoValidate = true;
      } else {
        this.pannoValidate = false;
      }
    },
    validateAadharClick(e) {
      const regex = new RegExp(/^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/);
      this.adharno.length == 4 ? (this.adharno = this.adharno.concat(" ")) : this.adharno;
      this.adharno.length == 9 ? (this.adharno = this.adharno.concat(" ")) : this.adharno;
      if (regex.test(this.adharno) == true) {
        this.adharnoValidate = true;
      } else {
        this.adharnoValidate = false;
      }
    },
    removeRow(id) {
      this.extra_customer.splice(id, 1);
    },
    addcsRow() {
      this.extra_customer.push({
        customer: "",
        email: "",
        mobile: "",
        aadhar: "",
        pan: "",
        nominee: "",
        nomineerelation: "",
        nomineedob: "",
        nomineephone: "",
      });
    },

    onClickBack() {
      localStorage.removeItem("plotData");

      if (this.dashboard) {
        this.$router.push("/project");
      } else {
        this.$router.push("/crm/sales");
      }
    },
    submitForm(e) {
      let part = true;
      let data;
      let extra_customer = this.extra_customer.filter((item) => item.customer);
      extra_customer = extra_customer.map((item) => item.customer.id);
      const data1 = {
        action: this.cancelStatus == "Yes" ? 1 : null,
        otp: this.otp,
        team: this.selectedTeam.id,
        project: this.selectedProject.id,
        plotno: this.selectedPlot.id,
        branch: this.selecttype,
        paymenttype: this.selectedPaymentType,
        paymentthrough: this.selectedPaymentThrough,
        bookingdate: moment(this.bookingdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        booktype: this.bookingtype,
        plotrate: this.plotRate,
        plotareasqyard: this.plotAreaYard,
        plotareasqfeet: this.plotAreaFeet,
        cancellation_date: moment(this.cancellation_date, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        discount: this.discount,
        disremarks: this.disremarks,
        plotvalue: this.plotValue,
        totalpayment: this.totalPayment,
        firstpayment: this.firstpayment,
        remainingpayment: this.remainingPayment,
        title: this.selectedtitle,
        installmentsdate: moment(this.installmentStartDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        noofinstallment: this.bookingtype !== "Full Payment" ? this.noInstallment : "",
        monthgap: this.betweenMonth,
        installmentamt:
          this.bookingtype !== "Full Payment" ? this.installment_amount : "",
        cfirstname: this.cfirstname.fullname,
        cfirstid: this.cfirstname.id,
        address: this.address,
        country: this.country,
        area: this.area,
        state: this.state,
        city: this.city,
        pincode: this.pincode,
        offer_id: this.offer ? this.offer.id : "",
        dateofbirth: moment(this.dateofbirth, "DD/MM/YYYY").format("YYYY-MM-DD"),
        mobileno: this.mobileNo,
        email: this.email,
        adharno: this.adharno,
        panno: this.panno,
        emailsend: this.emailsend,
        nominee: this.nominee,
        nomineerelationship: this.nomineerelationship,
        nomineephone: this.nomineephone,
        refno: this.refno,
        bankreceipt: this.bankreceipt,
        bankdate: moment(this.bankdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        bankbranch: this.bankbranch,
        bankremarks: this.bankremarks,

        nomineedob: this.nomineedob,
        paymenttermday: this.paymenttermday,
        bankname: this.bankName,
        chequeno: this.chequeNo,
        bookingminvalue: this.bookingminvalue,
        status: this.status,
        active: this.active,
        remarks: this.remarks,
        agreementper: this.agreementper,
        agreement: this.agreement,
        bookingminvalueper: this.bookingminvalueper,
        sendemail: this.sendemail == 1,
        sendsms: this.sendsms == 1,
        sendwhatsapp: this.sendwhatsapp == 1,
        bank_id: this.bank?.id,
        chequedate: moment(this.chequeDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        extra_customer: extra_customer.join(","),
        discount_attachment: this.discount_attachment,
      };
      if (this.bookingtype == "EMI" || this.bookingtype == "Part Payment") {
        this.installments.map((item) => {
          item.installment_date = moment(item.installment_date, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
        });
        const installment = {
          data: this.installments,
        };
        data = {
          ...data1,
          ...installment,
        };
      } else {
        data = data1;
      }
      this.$refs.simpleRules.validate().then(async (success) => {
        if (
          success == false ||
          this.pannoValidate == false ||
          this.adharnoValidate == false
        ) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.submitDisable = false;
        }
        if (this.bookingtype == "Part Payment") {
          let total = parseFloat(this.firstpayment);
          this.installments.map((item) => {
            total += parseFloat(item.installment_amount);
          });
          if (parseFloat(total.toFixed(2)) !== parseFloat(this.totalPayment)) {
            part = false;
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: "Installmant amount should be equal to total payment",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          this.submitDisable = false;
        }
        if (
          success &&
          this.pannoValidate == true &&
          this.adharnoValidate == true &&
          part == true
        ) {
          this.submitDisable = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/sales/${this.$route.params.id}`
              : `${baseApi}/sales`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = true;

              localStorage.removeItem("plotData");
              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : json.data.success
                  ? "Update SuccessFully"
                  : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/crm/sales");
            })
            .catch((error) => {
              this.submitDisable = false;

              const code = error.toString().includes("409");
              let message = "";
              if (code) {
                message = error.response?.data?.message?.original?.email[0];
                if (!message) {
                  message = error.response.data.message;
                }
                console.log(message);
              }
              this.$swal({
                title: "Error!",
                text: message ? `${message}` : error,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    async init() {
      this.getTeam();
      if (this.userRole == "channelpartner") {
        this.downlineCP();
        this.selecttype = "ChannelPartner";
      } else if (this.userRole == "employee" && this.userType !== "Account Assistant") {
        this.selectedTeam = {
          name: this.userName,
          id: this.userId,
        };
        this.userInfo(this.selectedTeam);
      } else if (this.userRole == "franchise") {
        this.selectedTeam = {
          name: this.userName,
          id: this.userId,
        };
        this.userInfo(this.selectedTeam);
      } else {
        this.getChannelPartner();
      }
      this.getFranchise();
      this.getProject();
      // this.getLedger()
      // this.getCustomer();
    },
    async downlineCP() {
      this.teamOption = [];
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUserByParentId/${this.loginData.id}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.CPOption = [this.loginData];
          response.data.data.map((item) => {
            this.CPOption.push(item);
            if (item.children.length > 0) {
              this.handleChild(item.children);
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    handleChild(childs) {
      childs.map((item) => {
        this.CPOption.push(item);
        if (item.children.length > 0) {
          this.handleChild(item.children);
        }
      });
    },
    getEmp(id) {
      // this.selecttype = 'ChannelPartner'
      if (this.selecttype == "ChannelPartner") {
        this.getChannelPartner(id);
      } else if (this.selecttype == "Franchise") {
        this.getFranchise(id);
      } else if (this.selecttype == "Employee") {
        this.getTeam(id);
      } else {
        return {
          id: this.userId,
        };
      }
    },
    async getTeam(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.teamOption = response.data.data;
          if (id !== undefined) {
            this.teamOption.map((item) => {
              if (item.id == id) {
                this.selectedTeam = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getChannelPartner(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getChannelpartner`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.CPOption = response.data.data;
          if (id !== undefined) {
            this.CPOption.map((item) => {
              if (item.id == id) {
                this.selectedTeam = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getFranchise(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getFranchise`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.FranchiseOption = response.data.data;
          if (id !== undefined) {
            this.FranchiseOption.map((item) => {
              if (item.id == id) {
                selectedTeam = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getProject(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOption = response.data.data;
          this.projectOption.map((item) => {
            if (id !== undefined) {
              if (item.id == id) {
                this.selectedProject = item;
                this.handleBank(item.bank_id);
                this.getPlot(this.selectedProject.id);
              }
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getOffer() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/get-offers`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.allOffer = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getPlot(id) {
      this.bookingOptions = [];
      this.offerOptions = [];
      this.phaseImage = {};
      this.selectedProjectBanks = [];
      this.assignBank = [];
      this.bank = {};
      this.emiOptions = [];
      this.projectId = "";
      this.visible = false;
      this.plot = "";
      if (id) {
        this.offerOptions = this.allOffer.filter(
          (item) => item.project_id.split(",").indexOf(id.toString()) !== -1
        );
        this.phaseImage.source = this.selectedProject.phaseattachment;
        this.selectedProjectBanks =
          this.selectedProject && this.selectedProject.bank_id
            ? this.selectedProject.bank_id.split(",")
            : [];
        this.assignBank = this.bankLedgerOption.filter(
          (item) => this.selectedProjectBanks.indexOf(item.id.toString()) !== -1
        );
        if (this.assignBank.length == 1 && !this.ifEdit) {
          this.bank = this.assignBank[0];
        }
        if (this.selectedProject.bookingtype) {
          let obj = JSON.parse(this.selectedProject.bookingtype);
          if (obj.pp) {
            this.bookingOptions.push("Part Payment");
          }
          if (obj.fp) {
            this.bookingOptions.push("Full Payment");
          }
          if (obj.emi) {
            this.bookingOptions.push("EMI");
          }
        }
        let emiArr = this.selectedProject.emi ? JSON.parse(this.selectedProject.emi) : [];
        this.emiOptions = [];
        emiArr.map((item, index) => {
          if (item.fromMonth < item.toMonth) {
            for (let month = item.fromMonth; month <= item.toMonth; month++) {
              this.emiOptions.push({ month: month, rate: item.rate });
            }
          }
        });

        this.handleBank(this.selectedProject.bank_id);
        this.projectId = id;
        this.visible = true;
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/plotno`,
          data: JSON.stringify({
            project_id: id,
          }),
        };
        await axios(requestOptions)
          .then((response) => {
            this.plotOption = response.data.data;
          })
          .catch((error) => console.log(error, "error"));
      } else {
        this.offerOptions = [];
        this.phaseImage.source = null;
        this.selectedProjectBanks = {};
        this.assignBank = [];
        this.selectedPlot = "";
        this.plotRate = "";
        this.plotAreaFeet = "";
        this.selectedPlot = "";
        this.plotValue = "";
        this.totalPayment = "";
        this.payment = "";
        this.testPlotValue = "";
      }
    },
    async handleBank(id) {
      this.bankLedgerOption.map((item) => {
        if (item.id == id) {
          this.bank = item;
        }
      });
    },
    async getPlotById(plot) {
      if (plot) {
        // this.plotObj = {}
        // this.plotObj[plot.id] = plot.id
        // const requestOptions = {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: `Bearer ${this.accessToken}`,
        //   },
        //   url: `${this.baseApi}/getplotno`,
        //   data: JSON.stringify({
        //     plot_id: plot.id,
        //   }),
        // };
        // await axios(requestOptions)
        //   .then((response) => {
        //     if (response.data.data.data[0]) {
        let data = plot;
        this.plotAreaYard = data.plotareayd;
        this.plotAreaFeet = data.plotareasqfeet;
        if (this.selecttype == "Franchise" || this.userRole == "franchise") {
          this.plotRate = isNaN(data.franchiserate) ? 0 : data.franchiserate;
          this.selectedPlot = data;
          const plotvalue = data.plotValueFr;
          this.plotValue = isNaN(plotvalue) ? 0 : plotvalue;
          this.totalPayment = data.plotAmountFr;
          this.payment = this.totalPayment;
          this.testPlotValue = this.plotValue;
        } else {
          this.plotRate = isNaN(data.rateperyd) ? 0 : data.rateperyd;
          this.plotAreaFeet = data.plotareasqfeet;
          this.selectedPlot = data;
          const plotvalue = data.plotValue;
          this.plotValue = isNaN(plotvalue) ? 0 : plotvalue;
          this.totalPayment = data.plotamount;
          this.payment = this.totalPayment;
          this.testPlotValue = this.plotValue;
        }
        this.handleBank(data.bank_id);
        this.calcFirstpayment();
        if (this.bookingtype == "EMI") {
          if (this.bookingminvalue == "") {
            this.clickMinValuePer();
          } else {
            this.clickMinValue();
          }
        }
        if (this.ifEdit) {
          this.calcPlotValue();
        }
        // }
        // })
        // .catch((error) => console.log(error, "error"));
      }
    },
    calcPlotValue(response) {
      if (this.bookingtype == "EMI") {
        this.clickMinValue();
        this.firstpayment = this.bookingminvalue;
      }
      this.totalPayment = this.payment;
      if (this.discount > this.payment) {
        this.discount = this.payment.toFixed(2);
      }
      // this.plotValue = this.testPlotValue;
      // const disc = this.discount;
      // const discPlot = (this.plotValue * this.discount) / 100;
      this.totalPayment = this.totalPayment - this.discount;
      // this.plotValue = this.plotValue - discPlot;
      this.clickAgreementPer();
      this.calcFirstpayment();
    },
    calcFirstpayment() {
      // eslint-disable-next-line init-declarations
      let minValue;

      if (this.selectedPaymentType == "Down Payment") {
        minValue = this.selectedProject ? this.selectedProject.bookingamount : false;
        // minValue =minValue ? minValue:(this.plotValue * 25) / 100 ;
        minValue = 10000;
        this.firstPaymentRules = `required|min_value:${minValue}`;
      } else if (this.selectedPaymentType == "Token") {
        if (this.bookingtype == "EMI") {
          minValue = this.bookingminvalue;
        } else {
          minValue = 10000;
        }
        this.firstPaymentRules = `required|min_value:${minValue}`;
      }
      this.remainingPayment = (this.totalPayment - this.firstpayment).toFixed(2);
    },
    // async getLedger() {
    //   const requestOptions = {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Authorization': `Bearer ${this.accessToken}`
    //     },
    //     url: `${this.baseApi}/ledger`
    //   }
    //   await axios(requestOptions)
    //     .then((response) => {
    //       this.ledgerOption = response.data.data.data
    //     })
    //     .catch((error) => console.log(error, 'error'))
    // },
    addRow() {
      let total = parseFloat(this.firstpayment);
      this.installments.map((item) => {
        total += parseFloat(item.installment_amount);
      });
      if (total < parseFloat(this.totalPayment)) {
        this.installments.push({
          installment_date: moment(new Date()).format("DD/MM/yyyy"),
          installment_amount: "",
          remarks: "",
          type: "Part Payment",
        });
      } else {
        if (total.toFixed(2) > parseFloat(this.totalPayment).toFixed(2)) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Installmant amount should be equal to total payment",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Installmant amount is equal to total payment",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      }
    },
    deleteRow(index) {
      this.installments.splice(index, 1);
    },
    handleInstallment(e, name) {
      if (e) {
        // if (name == "noInstallment") {
        this.noInstallment = e.month;
        this.installments = [];
        // if (e == "1") {
        //   this.installments.push({
        //     installment_date: moment(new Date()).format("DD/MM/yyyy"),
        //     installment_amount: this.installment_amount,
        //     remarks: "",
        //   });
        // } else
        //  {
        // for (let index = 0; index < parseInt(e); index++) {
        //   this.installments.push({
        //     installment_date: moment(new Date()).format('DD/MM/yyyy'),
        //     installment_amount: this.installment_amount,
        //     remarks: ''
        //   })
        // }
        this.installments.map((item) => {
          item.installment_amount = isNaN(
            parseFloat(this.installment_amount) / parseFloat(this.noInstallment)
          )
            ? 0
            : parseFloat(this.installment_amount) / parseFloat(this.noInstallment);
        });

        if (this.handlebetweenmonthname) {
          this.handleBetweenMonth();
        }
      }
      // }
    },

    handleBetweenMonth(e) {
      if (e) {
        this.handlebetweenmonthname = e;
        let R = this.noofemi.rate / 12 / 100;

        this.installment_amount =
          ((this.remainingPayment * R * (1 + R) ** this.noInstallment) /
            ((1 + R) ** this.noInstallment - 1)) *
          this.noInstallment;
        this.installment_amount = parseFloat(this.installment_amount.toFixed(2));
        const data = [];
        data.push(this.installmentStartDate);

        this.BetweenDate = this.installmentStartDate.split("/").reverse().join("-");
        for (let index = 1; index < parseInt(this.noInstallment); index++) {
          this.month = null;
          this.selectedDay = null;
          this.yearr = null;
          this.selectedMonth = null;
          this.selectedMonth = new Date(this.BetweenDate).getMonth();
          // this.selectedDay = this.installmentStartDate.getFullYear()
          this.month = parseInt(e) + parseInt(this.selectedMonth);
          // for (let index1 = this.selectedDay; this.month > 12; index1++) {
          //   this.month = parseInt(this.month) - 12
          //   this.yearr = index1
          // }
          this.BetweenDate = new Date(this.BetweenDate);

          this.BetweenDate.setMonth(parseInt(this.month));
          // this.BetweenDate.setYear(this.year)
          data.push(moment(new Date(this.BetweenDate)).format("DD/MM/yyyy"));
        }
        if (this.installments.length > 0) {
          data.map((item, index) => {
            this.installments[index].installment_date = item;
            this.installments[index].installment_amount = parseFloat(
              (this.installment_amount / this.noInstallment).toFixed(2)
            );
          });
        } else {
          this.installments = [];
          data.map((item) => {
            this.installments.push({
              installment_date: item,
              installment_amount: parseFloat(
                (this.installment_amount / this.noInstallment).toFixed(2)
              ),
              remarks: "",
            });
          });
        }
      }
    },
    handleInstallment_amount(e, name) {
      // this.installments.installment_amount = []
      this.installments.map((item) => {
        item.installment_amount = e / this.noInstallment;
        this.installment_amount = e;
      });
    },
    async getAssignSource(id) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUserByParentId/${userData.id}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.assignToOptions = [];
          this.assignToOptions = response.data.data;
          this.assignToOptions.map((item) => {
            this.CPOption.push(item);
            if (item.children && JSON.stringify(item.children) !== "[]") {
              this.assigntest(item.children);
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    assigntest(data) {
      data.map((item) => {
        this.CPOption.push(item);
        if (item.children && JSON.stringify(item.children) !== "[]") {
          this.assigntest1(item.children);
        }
      });
    },
    assigntest1(data) {
      data.map((item) => {
        this.CPOption.push(item);
        if (item.children && JSON.stringify(item.children) !== "[]") {
          this.assigntest(item.children);
        }
      });
    },
  },
};
</script>
<style>
.bankDetails {
  padding: 20px 10px;
  border: 1px solid #d8d6de;
  border-radius: 10px;
}
.bankDetailsChild {
  padding: 10px;
}
</style>
